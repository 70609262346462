
import axios from 'axios';
export default {
    
    async createShopSales(params)  {
        return await axios.post(`shop_sales/create` , params)
    },
    async createShopSalesList(params)  {
        return await axios.post(`shop_sales/create/list` , params)
    },
    async updateShopSalesColumn(column , value , data)  {
        return await axios.put(`shop_sales/update_list?${column}=${value}` , data)
    },
    async deleteShopSalesList(list)  {
        return await axios.delete(`shop_sales/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportShopSales(column , value)  {
        return await axios.get(`shop_sales/report?${column}=${value}`)
    },
    async getAllShopSales()  {
        return await axios.get(`shop_sales/all`)
    },
    async getOneShopSales(shop_sale_id)  {
        return await axios.get(`shop_sales/all/${shop_sale_id}`)
    },
    async getShopSalesByColumn(column , value)  {
        return await axios.get(`shop_sales/filter?column=${column}&value=${value}`)
    },
    async deleteShopSales(shop_sale_id)  {
        return await axios.delete(`shop_sales/delete/${shop_sale_id}`)
    },
    async updateShopSales(shop_sale_id , params)  {
        return await axios.put(`shop_sales/update/${shop_sale_id}` , params)
    }
}