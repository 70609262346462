
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<form @submit.prevent="updateShopSales" autocomplete="off">
				<v-layout row wrap>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="shop_sales.shop_sale_item" type="text" :label="$store.getters.language.data.shop_sales.shop_sale_item" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="shop_sales.shop_sale_price" type="text" :label="$store.getters.language.data.shop_sales.shop_sale_price" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="shop_sales.shop_sale_date" type="date" :label="$store.getters.language.data.shop_sales.shop_sale_date" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex> -->
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="shop_sales.shop_sale_note" type="text" :label="$store.getters.language.data.shop_sales.shop_sale_note" class="mx-1" filled outlined dense>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="shop_sales.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="success" type="submit">{{$store.getters.language.data.shop_sales.update_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
		</v-container>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/shop_sales.request.js'
	export default {
		data() {
			return {
				shop_sales: {},
				id: '',
				loading: true,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
			}
		},
		computed: {

			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			},

		},
		mounted() {
			this.id = this.$route.params.id
			this.getOneShopSales()
		},
		methods: {
			getOneShopSales() {
				this.loading = true
				requests.getOneShopSales(this.id).then(r => {
					if (r.status == 200) {
						this.shop_sales = r.data.row
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopSales',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopSales',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			updateShopSales() {
				this.loading = true
				this.shop_sales.user_id = this.user.user_id

				delete this.shop_sales.shop_sale_date
				requests.updateShopSales(this.id, this.shop_sales).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'ShopSales Updated',
							color: 'success'
						}
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    